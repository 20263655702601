import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    // Wait for document to be fully loaded and jQuery to be available
    document.addEventListener('DOMContentLoaded', () => {
      this.initMapbox();
    });
  }

  initMapbox() {
    try {
      // Make sure mapboxgl is loaded
      const mapboxgl = require('mapbox-gl');
      const MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');
      require('@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css');

      if (typeof window.process === 'undefined') {
        window.process = { env: {} };
      }
      
      console.log("Mapbox controller connected");
      
      // Make sure the API key is available
      if (!window.process.env.MAPBOX_API) {
        console.error("Mapbox API key is missing");
        return;
      }
      
      mapboxgl.accessToken = window.process.env.MAPBOX_API;
      
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: 'place,address',
        placeholder: 'Search for a location...',
        countries: 'gb'  // Limit to UK
      });

      // Add geocoder to the page
      if (this.element && this.element.parentElement) {
        const geocoderContainer = this.element.parentElement;
        geocoder.addTo(geocoderContainer);
        
        // Remove the original input as we'll use Mapbox's
        this.element.remove();

        // When a location is selected
        geocoder.on('result', (event) => {
          console.log("Location selected:", event.result);
          const [lng, lat] = event.result.center;
          
          // Update hidden fields
          const latField = document.getElementById('latitude');
          const lngField = document.getElementById('longitude');
          const form = document.getElementById('location-form');
          
          if (latField && lngField && form) {
            latField.value = lat;
            lngField.value = lng;
            form.submit();
          } else {
            console.error("Required form elements not found");
          }
        });

        // Add error handling
        geocoder.on('error', (event) => {
          console.error("Geocoder error:", event);
        });
      }
    } catch (error) {
      console.error("Error initializing Mapbox:", error);
    }
  }
} 