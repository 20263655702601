// Simple controller for handling the quick data modal
$(document).ready(function() {
  // Handle quick data button clicks
  $(document).on('click', '.quick-data-btn', function(e) {
    e.preventDefault();
    
    const url = $(this).data('url');
    
    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'script'
    });
  });
}); 