import { Controller } from 'stimulus';
import TurboHttpRequest from '../../helpers/turbo_http_request';

export default class extends Controller {
  fetch(e) {
    e.preventDefault();
    e.stopPropagation();

    const baseUrl = this.element.dataset.url;
    const query = (!this.element.name || this.element.name == '') ? null : `${this.element.name}=${this.element.value}`;
    const url = query ? (`${baseUrl}${baseUrl.includes('?') ? '&' : '?'}${query}`) : baseUrl;

    const turboHttpRequest = new TurboHttpRequest(url, this.element.dataset.method);
    turboHttpRequest.execute();

    // Prevent double submission by disabling the element
    const element = this.element;
    
    if (element.disabled || element.dataset.processing === "true") {
      e.preventDefault();
      return;
    }
    
    // Mark as processing
    element.dataset.processing = "true";
    
    if (element.tagName === "INPUT" || element.tagName === "BUTTON" || element.tagName === "SELECT") {
      element.disabled = true;
    }
    
    // Re-enable the element after a short delay
    setTimeout(() => {
      element.dataset.processing = "false";
      if (element.tagName === "INPUT" || element.tagName === "BUTTON" || element.tagName === "SELECT") {
        element.disabled = false;
      }
    }, 2000);
  }
}